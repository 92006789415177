@use "../../vars" as *;

@layer edwin {
  .base {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: $space-1x $space-5x;
    gap: $space-1x;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    // transitions
    transition:
      background-color 300ms,
      border-color 300ms,
      color 300ms;

    font-family: inherit;
    outline: none;
  }

  // sizes
  .xl {
    @include h1(false);
  }

  .lg {
    @include h2(false);
  }

  .md {
    @include h5(false);
  }

  .sm {
    @include body-small(false);
  }

  .xs {
    padding: $space-1x $space-6x;
    @include body-tag(false);
  }

  // variants
  .primary {
    background: $white;
    border: 1px solid $color-neutral-500;
    border-radius: 40px;
    color: $color-neutral-500;

    @include svgHoverStyles($color-base-300, $color-base-400, $color-base-400);

    &:hover {
      background: $color-neutral-100;
      color: $color-neutral-500;
    }

    &.focus,
    &:focus {
      color: $color-neutral-500;
      background: $color-neutral-100;
      box-shadow: 0 0 0 6px $color-neutral-300;
    }

    &.active,
    &:active {
      background: $color-neutral-500;
      color: $white;
    }

    &:disabled,
    &:disabled:active {
      background: $white;
      border: 1px solid $color-neutral-500;
      color: $color-neutral-500;
      opacity: 0.6;
    }
  }

  .secondary {
    background: $white;
    border: 1px solid $color-base-300;
    border-radius: $space-10x;
    color: $color-base-300;

    @include svgHoverStyles($color-neutral-500, $color-base-400, $color-base-400);

    &:hover {
      background: $color-base-400;
      color: $white;
    }

    &.focus,
    &:focus {
      color: $white;
      background: $color-base-400;
      box-shadow: 0 0 0 6px $color-base-300;
    }

    &.active,
    &:active {
      background: $color-base-300;
      color: $white;
    }

    &:disabled,
    &:disabled:active {
      background: $white;
      border: 1px solid $color-base-300;
      color: $color-base-300;
      opacity: 0.6;
    }
  }

  .tertiary {
    background: $color-neutral-400;
    border: 1px solid $color-neutral-400;
    border-radius: $space-2x;
    color: $white;

    @include svgHoverStyles($color-neutral-400, $color-base-200, $color-base-200);

    &:hover {
      background: $color-neutral-500;
      border: 1px solid $color-neutral-500;
    }

    &.focus,
    &:focus {
      color: $white;
      background: $color-neutral-500;
      box-shadow: 0 0 0 6px $color-neutral-200;
    }

    &.active,
    &:active {
      background: $color-base-300;
      border: 1px solid $color-base-300;
    }

    &:disabled,
    &:disabled:active {
      background: $color-neutral-400;
      border: 1px solid $color-neutral-400;
      opacity: 0.6;
    }
  }

  .danger {
    background: $white;
    border: 1px solid $red-300;
    border-radius: 40px;
    color: $red-300;

    @include svgHoverStyles($color-info-destructive, $red-400, $red-400);

    &:hover {
      background: $red-400;
      border: 1px solid $red-400;
      color: $white;
    }

    &.focus,
    &:focus {
      color: $white;
      background: $red-400;
      box-shadow: 0 0 0 4px $red-300;
      border-radius: $space-10x;
    }

    &.active,
    &:active {
      background: $red-300;
      color: $white;
    }

    &:disabled,
    &:disabled:active {
      background: $white;
      border: 1px solid $red-300;
      color: $red-300;
      opacity: 0.6;
    }
  }

  .ghost {
    color: $color-neutral-500;
    background: none;
    border: none;

    @include svgHoverStyles($color-neutral-500, $color-base-400, $color-base-400);

    &:hover {
      color: $color-neutral-400;
    }

    &:focus-visible {
      outline: 2px solid $color-base-300;
      border-radius: $space-10x;
    }

    &.active,
    &:active {
      color: $color-base-300;
      outline: none;
    }

    &:disabled,
    &:disabled:active {
      color: $color-neutral-500;
      opacity: 0.6;
    }
  }

  .unstyled {
    font-size: inherit;
    display: inline;
    padding: 0;
    color: $color-neutral-500;
    background: none;
    border: none;

    @include svgHoverStyles($color-neutral-500, $color-base-400, $color-base-400);

    &.active,
    &:active {
      color: $color-base-300;
    }

    &:hover {
      color: $color-neutral-400;
    }

    &:disabled,
    &:disabled:active {
      color: $color-neutral-500;
      opacity: 0.6;
    }
  }

  .disable-svg-styles {
    @include svgHoverStyles(revert-layer, revert-layer, revert-layer);
  }

  // icon position
  .left-icon,
  .right-icon {
    padding: $space-2x $space-5x;
  }
}
