@use '~@edwin-edu/ui/vars' as *;

.container {
  margin: 0 auto;
  height: 100%;
  max-width: 1100px;
  width: 100%;
  color: $color-neutral-400
}

.heading {
  flex: 0 0 auto;
  font-size: 1.5rem;
  font-weight: 600;
}

.backArrow {
  width: $space-3x;
  path {
    stroke: $color-base-300;
  }
}

.headerDivider {
  border-right: 1px solid $color-neutral-200;
  height: 100%;
}

.divider {
  border-right: 1px solid $color-neutral-500;
  height: calc(100% - 20px);
}

.resultCount {
  flex: 1 0 auto;
  font-weight: 700;
}

.scrollContainer {
  @include scrollbar();

  > div {
    height: 100%;
  }
}

.backButton{
  color: $color-neutral-400;
}